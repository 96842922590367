import React from 'react';
import './style.css'

import { Col, Container, Row } from 'react-bootstrap';
import { getDefaultLang } from '../../utils';

import image1 from './assets/image-1.png'
import image2 from './assets/image-2.png'
import image3 from './assets/image-3.png'
import image4 from './assets/image-4.png'

const langTitle = {
    'bih': {
        image: image1,
        subTitle: 'Sa pažnjom proizvodimo',
        title: 'Dugotrajne i prirodne',
        titleSpan: 'papirne kese.'
    },
    'en': {
        image: image2,
        subTitle: 'We produce with care',
        title: 'long-lasting and natural ',
        titleSpan: 'paper bags.'
    },
    'ge': {
        image: image3,
        subTitle: 'Wir produzieren mit Sorgfalt',
        title: 'langlebige und ökologische',
        titleSpan: 'Papiertüten her.'
    },
    'fr': {
        image: image4,
        subTitle: 'Nous produisons avec soin des',
        title: 'SACS EN PAPIER NATURELS ET LONG TERME.',
        titleSpan: ''
    }
}

const Section2 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-2" style={{ backgroundImage: `url(${langTitle[dafaultLang].image})` }}>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={7}></Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                    <h2 className="title-1-section-2">
                                        {langTitle[dafaultLang].subTitle}
                                    </h2>
                                    <h2 className="title-2-section-2">
                                        {langTitle[dafaultLang].title}
                                        <br />
                                        <span className="span-title-2-section-2">
                                            {langTitle[dafaultLang].titleSpan}
                                        </span>
                                    </h2>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section2