import React from 'react';
import './style.css'

import { Col, Container, Row } from 'react-bootstrap';

import qr from './assets/qr.svg'

import { getDefaultLang } from '../../utils';


const langTitle = {
    'bih': {
        text1: 'Kontakt',
        text2: 'Za sva pitanja vam stojimo na raspolaganju.',
        text3: 'Adresa',
        text4: 'Ramići bb, 78000 Banja Luka Bosna i Hercegovina',
        text5: 'Prikaži na mapi >',
        text6: 'Email',
        text7: 'office@lupaco-bl.com',
        text8: 'Telefon',
        text9: 'T/F: +387 51 388 090',
        text10: 'M: +387 65 457 543',
        text11: 'Digitalna vizitkarta',
        text12: 'Skeniranjem telefonom automatski nas dodajete u svoj imenik.',
        text13: 'Dodaj u kontakte >'
    },
    'en': {
        text1: 'Contact us',
        text2: 'For all questions we are at your disposal',
        text3: 'Address',
        text4: 'Ramići bb, 78000 Banja Luka Bosnia and Herzegovina',
        text5: 'Show on map >',
        text6: 'Email',
        text7: 'office@lupaco-bl.com',
        text8: 'Phone number',
        text9: 'T/F: +387 51 388 090',
        text10: 'M: +387 65 457 543',
        text11: 'Digital card',
        text12: 'Scanning with your phone automatically adds us to your phonebook.',
        text13: 'Add to contact list >'
    },
    'ge': {
        text1: 'Kontakt',
        text2: 'Wir stehen Ihnen für alle Fragen zur Verfügung.',
        text3: 'Adresse',
        text4: 'Ramići bb, 78000 Banja Luka, Bosnien und Herzegowina.',
        text5: 'MAP anzeigen >',
        text6: 'Email',
        text7: 'office@lupaco-bl.com',
        text8: 'Telefon',
        text9: 'T/F: +387 51 388 090',
        text10: 'M: +387 65 457 543',
        text11: 'Digitale Visitenkarte.',
        text12: 'Mit Smartphone scannen um uns einfach ins Telefonbuch hinzuzufügen.',
        text13: 'Zu Kontakten hinzufügen >'
    },
    'fr': {
        text1: 'Contact',
        text2: 'Nous sommes à votre disposition pour toutes questions.',
        text3: 'Adresse',
        text4: 'Ramići bb, 78000 Banja Luka, Bosnie-Herzégovine.',
        text5: 'Montrer sur la mappe >',
        text6: 'Email',
        text7: 'office@lupaco-bl.com',
        text8: 'Téléfon',
        text9: 'T/F: +387 51 388 090',
        text10: 'M: +387 65 457 543',
        text11: 'Carte de visite numérique.',
        text12: 'La numérisation par téléphone nous ajoute automatiquement à votre annuaire.',
        text13: 'Ajouter aux contacts >'
    }
}

const Section10 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-10" id="kontakt">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <div className="card-1-section-10">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={3}></Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <h2 className="title-1-section-10">
                                            {langTitle[dafaultLang].text1}
                                        </h2>
                                        <p className="text-1-section-10">
                                            {langTitle[dafaultLang].text2}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3}></Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={1}></Col>
                                    <Col xs={12} sm={12} md={12} lg={3}>
                                        <h2 
                                            className="title-2-section-10">
                                            {langTitle[dafaultLang].text3}
                                        </h2>
                                        <p className="text-2-section-10">
                                            {langTitle[dafaultLang].text4}
                                        </p>
                                        <div className="wrapper-link-2-section-2">
                                            <a className="link-2-section-10" href="https://g.page/lupaco?share" target="_blank" rel="noopener noreferrer">
                                                {langTitle[dafaultLang].text5}
                                            </a>
                                        </div>
                                        {/*  */}
                                        <h2 className="title-3-section-10">
                                            {langTitle[dafaultLang].text6}
                                        </h2>
                                        <p className="text-3-section-10">
                                            {langTitle[dafaultLang].text7}
                                        </p>
                                        {/*  */}
                                        <h2 className="title-4-section-10">
                                            {langTitle[dafaultLang].text8}
                                        </h2>
                                        <p className="text-4-section-10">
                                            {langTitle[dafaultLang].text9} <br />
                                            {langTitle[dafaultLang].text10}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3}></Col>
                                    <Col xs={12} sm={12} md={12} lg={4}>
                                        <div className="card-2-section-10">
                                            <h2 className="title-5-section-10">
                                                {langTitle[dafaultLang].text11}
                                            </h2>
                                            <img className="image-section-10" src={qr} alt="lupaco" />
                                            <p className="text-5-section-10">
                                                {langTitle[dafaultLang].text12}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={1}></Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section10