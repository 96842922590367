import React, { useEffect } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { setDefaultLang } from './utils'

// styles

import './assets/css/main/main.css'
import './assets/css/bootstrap/bootstrap-grid.css'

import Layout from './components/Layout'
import Home from './pages/Home'

const App = () => {

    useEffect(() => {
        setDefaultLang()
    }, [])

    return (
        <>
            <Router>
                <Layout>
                    <Switch>
                        <Route path="/" exact component={Home} />
                    </Switch>
                </Layout>
            </Router>
        </>
    )
}

export default App