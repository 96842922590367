export const DEFAULT_LANG = 'bih'

export const getDefaultLang = () => {
    const dafaultLang = localStorage.getItem('default_lang');

    return dafaultLang || DEFAULT_LANG;
}

export const setDefaultLang = () => {
    const dafaultLang = localStorage.getItem('default_lang');

    if (!dafaultLang) {
        localStorage.setItem('default_lang', DEFAULT_LANG);
    }
}