import React, { useState, useEffect } from 'react'
import Scrollspy from 'react-scrollspy'
import { Col, Container, Row } from 'react-bootstrap'
import { getDefaultLang } from '../../utils'

import './style.css'

import logo from './assets/logo-lupaco.svg'
import menu from './assets/menu.svg'
import close from './assets/close.svg'

import bih from './assets/bih.svg'
import en from './assets/en.svg'
import ge from './assets/ge.svg'
import fr from './assets/fr.svg'

const langLinksNav = {
    'bih': [
        { title: 'Početna', link: '#pocetna' },
        { title: 'Proizvodi', link: '#proizvodi' },
        { title: 'Specifikacije', link: '#specifikacije' },
        { title: 'O nama', link: '#o-nama' },
        { title: 'Kontakt', link: '#kontakt' }
    ],
    'en': [
        { title: 'Home', link: '#pocetna' },
        { title: 'Products', link: '#proizvodi' },
        { title: 'Specifications', link: '#specifikacije' },
        { title: 'About', link: '#o-nama' },
        { title: 'Contact', link: '#kontakt' }
    ],
    'ge': [
        { title: 'Startseite', link: '#pocetna' },
        { title: 'Produkte', link: '#proizvodi' },
        { title: 'Spezifikationen', link: '#specifikacije' },
        { title: 'Über uns', link: '#o-nama' },
        { title: 'Kontakt', link: '#kontakt' }
    ],
    'fr': [
        { title: `Page d'accueil`, link: '#pocetna' },
        { title: 'Produits', link: '#proizvodi' },
        { title: 'Spécifications', link: '#specifikacije' },
        { title: 'À propos de nous', link: '#o-nama' },
        { title: 'Contact', link: '#kontakt' }
    ]
}

const Nav = () => {

    const [isActive, setIsActive] = useState(false);

    const [activeNavItemId, setActiveNavItemId] = useState(false);

    const dafaultLang = getDefaultLang();

    useEffect(() => {
        const pageId = window.location.hash;
        if (pageId) {
            setActiveNavItemId(pageId)
        }
    }, [])

    const setLocales = (lang) => {
        try {
            localStorage.setItem('default_lang', lang);
            // refresh
            window.location.reload(false)
        }
        catch (error) {
            console.log({ error })
        }
    }

    return (
        <>
            <div className="nav">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <div className="wrapper-nav">
                                <a href="#pocetna">
                                    <img className="logo-nav" src={logo} alt="logo lupaco" />
                                </a>
                                <div className="wrapper-links-nav">
                                    <Scrollspy items={['pocetna', 'proizvodi', 'specifikacije', 'o-nama', 'kontakt']} currentClassName="link-nav--active">
                                        {
                                            langLinksNav[dafaultLang].map((item, index) => {

                                                const isActiveNavLink = (activeNavItemId === item.link) || (!activeNavItemId && index === 0);

                                                console.log(`[${item.link}]==>`, isActiveNavLink)

                                                const onPressNavLink = () => {
                                                    setActiveNavItemId(item.link)
                                                }

                                                return (
                                                    <a key={index} className={`link-nav`} href={item.link} onClick={onPressNavLink}>
                                                        {item.title}
                                                    </a>
                                                )
                                            })
                                        }
                                    </Scrollspy>
                                </div>
                                <div className="wrapper-lang-nav">
                                    <img onClick={() => setLocales('bih')} className={`image-lang-nav ${dafaultLang === 'bih' ? 'image-lang-nav--active' : ''}`} src={bih} alt="lang" />
                                    <img onClick={() => setLocales('en')} className={`image-lang-nav ${dafaultLang === 'en' ? 'image-lang-nav--active' : ''}`} src={en} alt="lang" />
                                    <img onClick={() => setLocales('ge')} className={`image-lang-nav ${dafaultLang === 'ge' ? 'image-lang-nav--active' : ''}`} src={ge} alt="lang" />
                                    <img onClick={() => setLocales('fr')} className={`image-lang-nav ${dafaultLang === 'fr' ? 'image-lang-nav--active' : ''}`} src={fr} alt="lang" />
                                </div>
                                <div className="wrapper-menu-nav">
                                    <img className="image-menu-nav" src={menu} alt="menu" onClick={() => setIsActive(!isActive)} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={`menu-nav ${isActive ? 'menu-nav--active' : ''}`}>
                <img className="icon-menu-nav" src={close} alt="icon" onClick={() => setIsActive(!isActive)} />
                <div className="wrapper-links-menu-nav">
                    <Scrollspy style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} items={['pocetna', 'proizvodi', 'specifikacije', 'o-nama', 'kontakt']} currentClassName="link-nav--active">
                        {
                            langLinksNav[dafaultLang].map((item, index) => {

                                const onPressNavLinkSm = () => {
                                    setActiveNavItemId(item.link);
                                    setIsActive(!isActive);
                                }

                                return (
                                    <a key={index} className={`link-menu-nav`} href={item.link} onClick={onPressNavLinkSm}>
                                        {item.title}
                                    </a>
                                )
                            })
                        }
                    </Scrollspy>
                </div>
                <div className="wrapper-image-lang-menu-nav">
                    <img onClick={() => setLocales('bih')} className={`image-lang-menu-nav ${dafaultLang === 'bih' ? 'image-lang-menu-nav--active' : ''}`} src={bih} alt="lang" />
                    <img onClick={() => setLocales('en')} className={`image-lang-menu-nav ${dafaultLang === 'en' ? 'image-lang-menu-nav--active' : ''}`} src={en} alt="lang" />
                    <img onClick={() => setLocales('ge')} className={`image-lang-menu-nav ${dafaultLang === 'ge' ? 'image-lang-menu-nav--active' : ''}`} src={ge} alt="lang" />
                    <img onClick={() => setLocales('fr')} className={`image-lang-menu-nav ${dafaultLang === 'fr' ? 'image-lang-menu-nav--active' : ''}`} src={fr} alt="lang" />
                </div>
            </div>
        </>
    )
}

export default Nav
