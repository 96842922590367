import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { getDefaultLang } from '../../utils';

import './style.css'

import image1 from './assets/image-1.png'
import image2 from './assets/image-2.png'

const langTitle = {
    'bih': {
        title: 'Vaš proizvod zaslužuje najbolje.',
        text: 'Papirne kese pružaju više prostora za vaš brending i prepoznatljive su gdje god ih kupac ponese sa sobom. Najveći svjetski brendovi svoje proizvode pakuju upravo u papirne kese.',
        button: 'Zatraži ponudu'
    },
    'en': {
        title: 'Your product deserves the best.',
        text: 'Paper bags provide more space for your branding and are recognizable wherever the customer takes them. The world’s largest brands pack their products in paper bags.',
        button: 'Ask offer'
    },
    'ge': {
        title: 'Ihr Produkt verdient das Beste.',
        text: 'Papiertüten bieten eine größere Werbefläche und haben überall einen hohen Wiedererkennungswert. Auch die größten Marken der Welt verpacken viele ihrer Produkte in Papiertüten.',
        button: 'Angebot suchen'
    },
    'fr': {
        title: 'Votre produit mérite le meilleur.',
        text: `Les sacs en papier offrent plus d'espace pour votre image de marque et sont reconnaissables partout où le client les emmène avec eux.Les plus grandes marques mondiales emballent leurs produits dans des sacs en papier.`,
        button: `Regardez l'offre`
    }
}

const Section6 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-6">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={2}></Col>
                                <Col xs={12} sm={12} md={12} lg={8}>
                                    <h2 className="title-section-6">
                                        {langTitle[dafaultLang].title}
                                    </h2>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={2}></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={3}></Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <p className="text-section-6">
                                        {langTitle[dafaultLang].text}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={3}></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="wrapper-link-section-6">
                                        <a className="link-section-6" href="#kontakt">
                                            {langTitle[dafaultLang].button}
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="wrapper-image-section-6">
                                        <img className="image-section-6 visible-lg" src={image1} alt="lupaco" />
                                        <img className="image-section-6 visible-sm" src={image2} alt="lupaco" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section6
