import React from 'react';
import './style.css'

import { Col, Container, Row } from 'react-bootstrap';
import { getDefaultLang } from '../../utils';

import image from './assets/image.png'

const langTitle = {
    'bih': {
        title: 'Dostupne u različitim veličinama, u dvije boje.',
        spanText: 'Odaberite model, dimenzije, boju i materijal ',
        text: 'koji savršeno odgovaraju vašem artiklu.',
        button: 'Pogledaj ponudu >'
    },
    'en': {
        title: 'Available in different sizes, in two colours.',
        spanText: 'Choose the model, dimensions, color and material ',
        text: 'that perfectly match your item.',
        button: 'See more >'
    },
    'ge': {
        title: 'Erhältlich in verschiedenen Größen und zwei verschiedenen Farben.',
        spanText: 'Wählen Sie Modell, Farbe, Material und Abmessungen, ',
        text: 'die am besten zu Ihrem Produkt passen.',
        button: 'Angebot >'
    },
    'fr': {
        title: 'Disponible en différentes tailles, en deux couleurs. ',
        spanText: 'Choisissez le modèle, les dimensions, la couleur et la matière ',
        text: 'qui correspondent parfaitement à votre article.',
        button: `Regardez l'offre >`
    }
}

const Section5 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-5">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <div className="card-section-5">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="wrapper-image-section-5">
                                            <img className="image-section-5" src={image} alt="lupaco" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={2}></Col>
                                    <Col xs={12} sm={12} md={12} lg={8}>
                                        <h2 className="title-section-5">
                                            {langTitle[dafaultLang].title}
                                        </h2>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={2}></Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={3}></Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <p className="text-section-5">
                                            <span className="bold-text-section-5">
                                                {langTitle[dafaultLang].spanText}
                                            </span>
                                            {langTitle[dafaultLang].text}
                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3}></Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="wrapper-link-section-5">
                                            <a className="link-section-5" href="#specifikacije">
                                                {langTitle[dafaultLang].button}
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section5