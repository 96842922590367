import React from 'react'
import Slider from "react-slick";

import './style.css'
import './assets/slick.css'

import image1 from './assets/1.jpg'
import image2 from './assets/2.jpg'
import image3 from './assets/3.jpg'
import image4 from './assets/4.jpg'
import image5 from './assets/5.jpg'
import image6 from './assets/6.jpg'
import image7 from './assets/7.jpg'
import image8 from './assets/8.jpg'
import image9 from './assets/9.jpg'
import image10 from './assets/10.jpg'
import image11 from './assets/11.jpg'
import image12 from './assets/12.jpg'
import image13 from './assets/13.jpg'
import image14 from './assets/14.jpg'
import image15 from './assets/15.jpg'
import image16 from './assets/16.jpg'
import image17 from './assets/17.jpg'
import image18 from './assets/18.jpg'
import image19 from './assets/19.jpg'
import image20 from './assets/20.jpg'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const images = [
    { image: image1 },
    { image: image2 },
    { image: image3 },
    { image: image4 },
    { image: image5 },
    { image: image6 },
    { image: image7 },
    { image: image8 },
    { image: image9 },
    { image: image10 },
    { image: image11 },
    { image: image12 },
    { image: image13 },
    { image: image14 },
    { image: image15 },
    { image: image16 },
    { image: image17 },
    { image: image18 },
    { image: image19 },
    { image: image20 }
]

const Section9 = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slider = useRef(null);

    const settings = {
        autoplay: false,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        draggable: true,
        fade: false,
        focusOnSelect: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    autoplay: true,
                    speed: 100,
                    pauseOnHover: true,
                }
            }
        ],
        beforeChange: (current, next) => {
            setCurrentSlide(next);
        }
    };

    useEffect(() => {
        if (!slider.current) return;
    }, [slider]);

    const prevSlide = () => {
        slider.current.slickGoTo(currentSlide - 1);
    }

    const nextSlide = () => {
        slider.current.slickGoTo(currentSlide + 1);
    }

    return (
        <>
            <div
                style={{ position: 'relative' }}
                className="section-9"
            >
                <button
                    onClick={prevSlide}
                    style={{
                        left: 0,
                        position: 'absolute',
                        height: "100%",
                        zIndex: 10,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        cursor: "pointer"
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 100, color: "#fff" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button
                    onClick={nextSlide}
                    style={{
                        right: 0,
                        position: 'absolute',
                        height: "100%",
                        zIndex: 10,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        cursor: "pointer"
                    }}
                >
                    <svg style={{ width: 100, color: '#fff' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                </button>
                <Slider ref={slider} {...settings}>
                    {
                        images.map((item) =>
                            <div key={image1} className="slide-section-9">
                                <img className="image-section-9" src={item.image} alt="lupaco" />
                            </div>
                        )
                    }
                </Slider>

            </div>
        </>
    )
}

export default Section9
