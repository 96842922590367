import React from 'react';
import './style.css'

import { Col, Container, Row } from 'react-bootstrap';
import { getDefaultLang } from '../../utils';

const langTitle = {
    'bih': {
        spanText1: 'Naše kese su od prirodnih materijala i proizvode se po najvišim ekološkim standardima. ',
        text1: 'Nakon upotrebe brzo se razgrađuju i zato su potpuno neškodljive za okolinu. Izborom papirnih kesa za vaš proizvod šaljete ispravnu poruku koju će vaši kupci prepoznati - da je briga za životnu sredinu jednako važna za sve.',
        spanText2: 'Papirne kese su pouzdane i izdržljive. ',
        text2: 'U zavisnosti od težine papira koju izaberete, mogu se koristiti i više puta. Kvalitetan materijal i provjerene tehnike koju koristimo pri proizvodnji čine ih uvijek jednako dugotrajnim.'
    },
    'en': {
        spanText1: 'Our bags are made of natural materials and are produced according to the highest environmental standards. ',
        text1: 'They decompose quickly after use which makes them completely harmless to the environment. By choosing paper bags for your product, you are sending the right message that your customers will recognize - that caring for the environment is equally important to everyone.',
        spanText2: 'Paper bags are reliable and durable. ',
        text2: 'Depending on the weight of the paper you choose, they can be used multiple times. High-quality material and proven technique that we use in production always make them equally enduring.'
    },
    'ge': {
        spanText1: 'Unsere Papiertüten werden aus natürlichen Materialien hergestellt und erfüllen dabei die höchsten ökologischen Standards. ',
        text1: 'Nach dem Gebrauch bauen sich die Tüten schnell ab und sind deshalb relativ unschädlich für die Umwelt. Indem Sie sich für unsere Papiertüten entscheiden, treffen Sie die richtige Wahl für den Schutz der Umwelt. Dies werden auch Ihre Kunden erkennen und wertschätzen, da der Schutz der Umwelt für alle glerichermaßen wichtig ist.',
        spanText2: 'Usere Tüten sind zuverlässig und haltbar. ',
        text2: 'Je nach Gewicht des gewählten Papiers können sie über einen längeren Zeitraum verwendet werden. Die hochwertigen Materialien und bewährten Technicken, die bei ihrer Herstellung verwendet werden, machen sie extrem langlebig.'
    },
    'fr': {
        spanText1: 'Nos sacs sont fabriqués à partir de matériaux naturels et sont fabriqués selon les normes environnementales les plus élevées. ',
        text1: `Après utilisation, ils se décomposent rapidement et sont donc totalement inoffensifs pour l'environnement.En choisissant des sacs en papier pour votre produit, vous envoyez le bon message que vos clients reconnaîtront: prendre soin de l'environnement est également important pour tout le monde.`,
        spanText2: 'Les sacs en papier sont fiables et durables. ',
        text2: 'Selon le grammage du papier que vous choisissez, ils peuvent être utilisés plusieurs fois. Le matériau de qualité et les techniques éprouvées que nous utilisons dans la production les rendent toujours aussi durables.'
    }
}

const Section3 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-3">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={7}></Col>
                                <Col xs={12} sm={12} md={12} lg={4}>
                                    <p className="text-section-3">
                                        <span className="span-text-section-3">
                                            {langTitle[dafaultLang].spanText1}
                                        </span>
                                        {langTitle[dafaultLang].text1}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={1}></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={1}></Col>
                                <Col xs={12} sm={12} md={12} lg={4}>
                                    <p className="text-section-3">
                                        <span className="span-text-section-3">
                                            {langTitle[dafaultLang].spanText2}
                                        </span>
                                        {langTitle[dafaultLang].text2}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section3