import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { getDefaultLang } from '../../utils'

import './style.css'


const langTitle = {
    'bih': {
        text1: 'Uzvrati prirodi. Smanji upotrebu plastičnih kesa - sačuvaj planetu Zemlju.',
        text2: 'Sva prava zadržana. Lupaco© 2021'
    },
    'en': {
        text1: 'Say thanks to nature. Reduce the use of plastic bags - save the planet Earth.',
        text2: 'All rights reserved. Lupaco© 2021'
    },
    'ge': {
        text1: 'Helfen Sie der Umwelt sich zu regenerieren. Reduzieren Sie die Verwendung von Plastiktüten – helfen Sie den Planeten zu retten.',
        text2: 'Alle Rechte vorbehalten. Lupaco© 2021'
    },
    'fr': {
        text1: `Retour à la nature. Réduisez l'utilisation de sacs en plastique - sauvez la planète Terre. Tous les droits sont réservés.`,
        text2: 'Tous les droits sont réservés. Lupaco© 2021'
    }
}

const Footer = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="footer">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <p className="text-footer">
                                {langTitle[dafaultLang].text1}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="copyright">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-left-copyright">
                                    <p className="text-copyright">
                                        {langTitle[dafaultLang].text2}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} className="col-right-copyright">
                                    <p className="text-copyright">
                                        +387 51 388 090   •   office@lupaco-bl.com
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer