import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { getDefaultLang } from '../../utils';

import './style.css'

// images
import image1 from './assets/image1.svg'
import image2 from './assets/image2.svg'
import image3 from './assets/image3.svg'
import image4 from './assets/image4.svg'
import image5 from './assets/image5.svg'
import image6 from './assets/image6.svg'
import image7 from './assets/image7.svg'
import image8 from './assets/image8.svg'
import image9 from './assets/image9.svg'
import image10 from './assets/image10.svg'
import image11 from './assets/image11.svg'
import image12 from './assets/image12.svg'

import image13 from './assets/image13.svg'
import image14 from './assets/image14.svg'
import image15 from './assets/image15.svg'
import image16 from './assets/image16.svg'

import image17 from './assets/image17.png'
import image18 from './assets/image18.png'

import image19 from './assets/image19.png'
import image20 from './assets/image20.png'

import image21 from './assets/image21.png'
import image22 from './assets/image22.png'

import image23 from './assets/image23.png'
import image24 from './assets/image24.png'

const langText = {
    'bih': {
        text1: 'Tehničke specifikacije',
        text2: 'Modeli',
        text3: 'bez ručke',
        text4: 'unutrašnja ravna ručka',
        text5: 'vanjska ravna ručka',
        text6: 'Materijali',
        text7: 'reciklirani papir',
        text8: '100% celulozni',
        text9: 'Boje',
        text10: 'Bijela (izbjeljena)',
        text11: 'Smeđa (neizbjeljena)',
        text12: 'Težine papira',
        text13: '70 g/m2',
        text14: '80 g/m2',
        text15: '90 g/m2',
        text16: '100 g/m2',
        text17: '110 g/m2',
        text18: 'Dimenzije',
        text19: 'Odlike',
        text20: 'pogodne za hranu',
        text21: 'vrhunski kvalitet',
        text22: 'spremne za štampu',
        text23: 'ekološki zahvalne',
        button: 'Naruči'
    },
    'en': {
        text1: 'Technical specifications',
        text2: 'Models',
        text3: 'Without handle SOS',
        text4: 'Internal flat handle',
        text5: 'External flat handle',
        text6: 'Materials',
        text7: 'Recycled paper',
        text8: '100% cellulose paper',
        text9: 'Colours',
        text10: 'White (bleached)',
        text11: 'Brown (unbleached)',
        text12: 'Paper weight',
        text13: '70 g/m2',
        text14: '80 g/m2',
        text15: '90 g/m2',
        text16: '100 g/m2',
        text17: '110 g/m2',
        text18: 'Dimensions',
        text19: 'Characteristics',
        text20: 'Suitable for food',
        text21: 'Proven quality',
        text22: 'Ready to print',
        text23: 'Eco-friendly',
        button: 'Order'
    },
    'ge': {
        text1: 'Technische Daten',
        text2: 'Modelle',
        text3: 'Ohne Griff (SOS Tüten)',
        text4: 'innenliegender Flachhenkelgriff',
        text5: 'aussenliegender Flachhenkelgriff',
        text6: 'Material',
        text7: 'Recyclingpapier',
        text8: '100% Zellulose-Papier',
        text9: 'Farben',
        text10: 'Weiß (gebleicht)',
        text11: 'Braun (ungebleicht)',
        text12: 'Papiergewicht',
        text13: '70 g/m2',
        text14: '80 g/m2',
        text15: '90 g/m2',
        text16: '100 g/m2',
        text17: '110 g/m2',
        text18: 'Dimensions',
        text19: 'Characteristics',
        text20: 'Suitable for food',
        text21: 'Proven quality',
        text22: 'Ready to print',
        text23: 'Eco-friendly',
        button: 'Order'
    },
    'fr': {
        text1: 'Spécifications techniques',
        text2: 'Modèles',
        text3: 'sans poignée (sac SOS)',
        text4: 'poignée intérieure plate',
        text5: 'poignée extérieure plate',
        text6: 'Matériaux',
        text7: 'papier recyclé',
        text8: 'papier 100 % cellulose',
        text9: 'Couleurs ',
        text10: 'blanc (blanchi)',
        text11: 'marron (écrus)',
        text12: 'Grammages du papier',
        text13: '70 g/m2',
        text14: '80 g/m2',
        text15: '90 g/m2',
        text16: '100 g/m2',
        text17: '110 g/m2',
        text18: 'Dimensions',
        text19: 'Caractéristiques',
        text20: 'alimentaire',
        text21: 'qualité supérieure',
        text22: 'prêt à imprimer ',
        text23: `respectueux de l'environnement`,
        button: 'Passer votre commande'
    }
}

const langTable = {
    'bih': {
        text1: 'model',
        text2: 'širina',
        text3: 'dno',
        text4: 'visina'
    },
    'en': {
        text1: 'Model',
        text2: 'Width',
        text3: 'Bottom',
        text4: 'Height'
    },
    'ge': {
        text1: 'Modell',
        text2: 'Breite',
        text3: 'Unterseite',
        text4: 'Höhe'
    },
    'fr': {
        text1: 'Modèle',
        text2: 'Largeur',
        text3: 'Bas',
        text4: 'Hauteur'
    }
}

const langTableDetail = {
    'bih': {
        text1: 'unut./bez',
        text2: 'vanj./bez'
    },
    'en': {
        text1: 'int./without',
        text2: 'ext./without'
    },
    'ge': {
        text1: 'int./without',
        text2: 'ext./without'
    },
    'fr': {
        text1: 'int./without',
        text2: 'ext./without'
    }
}

const langImage = {
    'bih': {
        image1: image17,
        image2: image18
    },
    'en': {
        image1: image19,
        image2: image20
    },
    'ge': {
        image1: image21,
        image2: image22
    },
    'fr': {
        image1: image23,
        image2: image24
    }
}

const Item = ({ image, children }) => {
    return (
        <>
            <div className="wrapper-item-section-7">
                <img className="image-item-section-7" src={image} alt="lupaco" />
                <p className="text-item-section-7">
                    {children}
                </p>
            </div>
        </>
    )
}

const Title = ({ title }) => {
    return (
        <>
            <h2 className="title-item-section-7">
                {title}
            </h2>
        </>
    )
}

const Section6 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-7" id="specifikacije">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <div className="card-section-7">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={4} className="visible-lg"></Col>
                                    <Col xs={12} sm={12} md={12} lg={4}>
                                        <h2 className="title-section-7">
                                            {langText[dafaultLang].text1}
                                        </h2>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={4} className="visible-lg"></Col>
                                </Row>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text2} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image2}>
                                                {langText[dafaultLang].text4}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={1} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image3}>
                                                {langText[dafaultLang].text5}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={1} className="visible-lg"></Col>
                                        <Col xs={12} sm={12} md={12} lg={2}>
                                            <Item image={image1}>
                                                {langText[dafaultLang].text3}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text6} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={3}>
                                            <Item image={image4}>
                                                {langText[dafaultLang].text7}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={3}>
                                            <Item image={image5}>
                                                {langText[dafaultLang].text8}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text9} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={3}>
                                            <Item image={image6}>
                                                {langText[dafaultLang].text10}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={3}>
                                            <Item image={image7}>
                                                {langText[dafaultLang].text11}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text12} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={1} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image8}>
                                                {langText[dafaultLang].text13}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image9}>
                                                {langText[dafaultLang].text14}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image10}>
                                                {langText[dafaultLang].text15}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image11}>
                                                {langText[dafaultLang].text16}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={2}>
                                            <Item image={image12}>
                                                {langText[dafaultLang].text17}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={1} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text18} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                        <Col xs={12} sm={12} md={12} lg={3}>
                                            <div className="wrapper-item-section-7">
                                                <img className="dimension-image-section-7" src={langImage[dafaultLang].image1} alt="lupaco" />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={3}>
                                            <div className="wrapper-item-section-7">
                                                <img className="dimension-image-section-7" src={langImage[dafaultLang].image2} alt="lupaco" />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={3} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                        <Col xs={12} sm={12} md={12} lg={8}>
                                            <div style={{ width: '100%' }}>
                                                <table className="table-section-7">
                                                    <tr>
                                                        <th>{langTable[dafaultLang].text1}</th>
                                                        <th>{langTable[dafaultLang].text2}</th>
                                                        <th>{langTable[dafaultLang].text3}</th>
                                                        <th>{langTable[dafaultLang].text4}</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>18 cm</td>
                                                        <td>8 cm</td>
                                                        <td>22 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>22 cm</td>
                                                        <td>10 cm</td>
                                                        <td>28 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>22 cm</td>
                                                        <td>10 cm</td>
                                                        <td>36 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>26 cm</td>
                                                        <td>14 cm</td>
                                                        <td>30 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>26 cm</td>
                                                        <td>17 cm</td>
                                                        <td>25 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>26 cm</td>
                                                        <td>17 cm</td>
                                                        <td>28 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>26 cm</td>
                                                        <td>20 cm</td>
                                                        <td>28 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>32 cm</td>
                                                        <td>12 cm</td>
                                                        <td>40 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>32 cm</td>
                                                        <td>16 cm</td>
                                                        <td>35 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>32 cm</td>
                                                        <td>16 cm</td>
                                                        <td>44 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>32 cm</td>
                                                        <td>17 cm</td>
                                                        <td>27 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>32 cm</td>
                                                        <td>22 cm</td>
                                                        <td>24 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text1}</td>
                                                        <td>45 cm</td>
                                                        <td>17 cm</td>
                                                        <td>48 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text2}</td>
                                                        <td>25 cm</td>
                                                        <td>14 cm</td>
                                                        <td>30 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text2}</td>
                                                        <td>22 cm</td>
                                                        <td>11 cm</td>
                                                        <td>25 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{langTableDetail[dafaultLang].text2}</td>
                                                        <td>18 cm</td>
                                                        <td>9 cm</td>
                                                        <td>23 cm</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <div className="group-section-7">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Title title={langText[dafaultLang].text19} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image13} >
                                                {langText[dafaultLang].text20}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image14}>
                                                {langText[dafaultLang].text21}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image15}>
                                                {langText[dafaultLang].text22}
                                            </Item>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={2}>
                                            <Item image={image16}>
                                                {langText[dafaultLang].text23}
                                            </Item>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={2} className="visible-lg"></Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="wrapper-link-section-7">
                                            <a className="link-section-7" href="#kontakt">
                                                {langText[dafaultLang].button}
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section6
