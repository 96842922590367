import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { getDefaultLang } from '../../utils';

import './style.css'

import image from './assets/image.svg'
import document from './assets/document.jpg'

const langTitle = {
    'bih': {
        title: 'Mi smo ',
        spanTitle: 'Lupaco.',
        subTitle: 'Isporučujemo samo najbolje za vas.',
        text1: 'Mi smo kompanija osnovana 2012. godine sa sjedištem u gradu Banja Luka, Bosna i Hercegovina.',
        text2: 'Pružamo kompletna rješenja za papirnu ambalažu od sirovina do dizajna, štampe, konverzije i isporuke gotovih proizvoda.',
        button: 'prikaži sertifikat'
    },
    'en': {
        title: 'We are ',
        spanTitle: 'Lupaco.',
        subTitle: 'We deliver what is best for you.',
        text1: 'We are a company founded in 2012 with headquarters in Banja Luka, Bosnia and Herzegovina.',
        text2: 'We provide complete solutions for paper packaging from raw materials to design, printing, conversion and delivery of finished products.',
        button: 'show certificate'
    },
    'ge': {
        title: 'Wir sind ',
        spanTitle: 'Lupaco.',
        subTitle: 'Wir empfehlen Ihnen nur das Beste vom Besten.',
        text1: 'Unser Unternehmen wurde 2012 gegründet und hat seinen Firmensitz in Banja Luka, Bosnien und Herzegowina.',
        text2: 'Wir bieten Komplettlösungen für die Papierindustrie, von Rohmaterial über das Design, das Bedrucken und der Verarbeitung bis hin zum Vertrieb der fertigen Produkte.',
        button: 'Zertifikat anzeigen'
    },
    'fr': {
        title: 'Nous sommes ',
        spanTitle: 'Lupaco.',
        subTitle: 'Nous ne livrons que le meilleur pour vous.',
        text1: 'Nous sommes une entreprise fondée en 2012 dont le siège est à Banja Luka, en Bosnie-Herzégovine.',
        text2: `Nous fournissons des solutions complètes pour l'emballage en papier, des matières premières à la conception, l'impression, la conversion et la livraison des produits finis.`,
        button: 'Montrer le certificat'
    }
}

const Section8 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-8" id="o-nama">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={1}></Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <h2 className="title-1-section-8">
                                        {langTitle[dafaultLang].title}
                                        <span className="span-title-1-section-8">
                                            {langTitle[dafaultLang].spanTitle}
                                        </span>
                                    </h2>
                                    <h2 className="title-2-section-8">
                                        {langTitle[dafaultLang].subTitle}
                                    </h2>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}></Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={1}></Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                    <p className="text-1-section-8">
                                        {langTitle[dafaultLang].text1}
                                    </p>
                                    <p className="text-2-section-8">
                                        {langTitle[dafaultLang].text2}
                                    </p>
                                    <img className="image-section-8" src={image} alt="lupaco" />
                                    <a className="link-section-8" href={document} target="_blank" rel="noopener noreferrer">
                                        {langTitle[dafaultLang].button}
                                    </a>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section8
