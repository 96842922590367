import React from 'react'

import Nav from '../Nav'
import Footer from '../Footer'
import Loader from '../Loader'

import './style.css'

const Layout = ({ children }) => {
    return (
        <>
            <Loader />
            <Nav />
            <div className="layout">
                {children}
            </div>
            <Footer />
        </>
    )
}

export default Layout