import React from 'react';

import Section1 from '../../sections/Section1';
import Section2 from '../../sections/Section2';
import Section3 from '../../sections/Section3';
import Section4 from '../../sections/Section4';
import Section5 from '../../sections/Section5';
import Section6 from '../../sections/Section6';
import Section7 from '../../sections/Section7';
import Section8 from '../../sections/Section8';
import Section9 from '../../sections/Section9';
import Section10 from '../../sections/Section10';

const Home = () => {
    return (
        <>
            <Section1 />
            <div id="proizvodi">
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
                <Section6 />
            </div>
            <Section7 />
            <Section8 />
            <Section9 />
            <Section10 />
        </>
    )
}

export default Home