import React from 'react';
import './style.css'
import { getDefaultLang } from '../../utils';
import { Col, Container, Row } from 'react-bootstrap';

import image from './assets/image.png'

const langTitle = {
    'bih': { title: 'Vrhunske papirne kese.', span: 'Za sve namjene.' },
    'en': { title: 'High-quality paper bags.', span: 'For every use.' },
    'ge': { title: 'Qualitativ hochwertige Papiertüten.', span: 'Für alle möglichen Gebrauchs-anwendungen.' },
    'fr': { title: 'Sacs en papier de qualité supérieure.', span: 'À toutes fins utiles.' }
}

const Section1 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="section-1" id="pocetna">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row className="row-section-1">
                                <Col xs={12} sm={12} md={12} lg={5}>
                                    <h2 style={{ position: 'relative', zIndex: 10 }} className="title-section-1">
                                        {langTitle[dafaultLang].title}
                                        <br />
                                        <span className="span-title-section-1">
                                            {langTitle[dafaultLang].span}
                                        </span>
                                    </h2>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                    <div className="wrapper-image-section-1">
                                        <img className="image-section-1" src={image} alt="lupaco" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section1