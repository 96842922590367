import React from 'react';
import './style.css'

import { Col, Container, Row } from 'react-bootstrap';
import { getDefaultLang } from '../../utils';

import image1 from './assets/image-1.png'
import image2 from './assets/image-2.png'
import image3 from './assets/image-3.png'
import image4 from './assets/image-4.png'

const langTitle = {
    'bih': {
        image: image1,
        span: 'Sigurne',
        title: 'za svaki proizvod',
        spanText: 'Posebnu pažnju posvećujemo higijenskoj ispravnosti naših kesa. ',
        text: 'Zahvaljujući visokim standardima i sigurnim materijalima, proizvodimo kese pogodne za prehrambene namirnice, sanitarne proizvode, i druge artikle koji mogu uticati na zdravlje ljudi.'
    },
    'en': {
        image: image2,
        span: 'Safe',
        title: 'for every product',
        spanText: 'We pay special attention to the hygienic quality of our bags. ',
        text: 'Thanks to high standards and safe materials, we produce bags suitable for food, sanitary products, and other items that can affect human health.'
    },
    'ge': {
        image: image3,
        span: 'Sicher',
        title: 'für jeden Bedarf die richtige Verpackung.',
        spanText: 'Besonderes Augenmerk setzen wir auf die Hygiene unserer Produkte. ',
        text: 'Dank hoher Standards und hochwertiger Materialien produzieren wir Tüten, die für den Transport von Lebensmitteln, Sanitärprodukten und anderen Sachen, die die menschliche Gesundheit beeinträchtigen können, geeignet sind.'
    },
    'fr': {
        image: image4,
        span: 'Sûr',
        title: 'pour chaque produit.',
        spanText: `Nous portons une attention particulière à l'hygiène de nos sacs. `,
        text: `Grâce à des normes élevées et à des matériaux sûrs, nous produisons des sacs adaptés aux aliments, aux produits sanitaires et à d'autres articles pouvant affecter la santé humaine.`
    }
}

const Section4 = () => {

    const dafaultLang = getDefaultLang();

    return (
        <>
            <div className="wrapper-image-section-4">
                <img className="image-section-4" src={langTitle[dafaultLang].image} alt="lupaco" />
            </div>
            <div className="section-4">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={12} md={12} lg={10}>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={1}></Col>
                                <Col xs={12} sm={12} md={12} lg={6}>
                                    <h2 className="title-section-4">
                                        {langTitle[dafaultLang].span}
                                        <br /><span className="span-title-section-4">
                                            {langTitle[dafaultLang].title}
                                        </span>
                                    </h2>
                                    <p className="text-section-4">
                                        <span className="span-text-section-4">{langTitle[dafaultLang].spanText}</span>
                                        {langTitle[dafaultLang].text}
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Section4