import React from 'react';

// style
import './style.css'

// icons
import logo from './assets/logo.svg'

const Loader = () => {
    return (
        <div className="loader">
            <img className="logo-loader" src={logo} alt="logo" />
        </div>
    )
}

export default Loader;